import React from 'react';

export const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        left: '10px',
        zIndex: 1,
      }}
      onClick={onClick}
    >
      <span style={{ fontSize: '30px', color: 'black' }}>&#10094;</span>
    </div>
  );
};

export const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        right: '50px',
        zIndex: 1,
      }}
      onClick={onClick}
    >
      <span style={{ fontSize: '30px', color: 'black' }}>&#10095;</span>
    </div>
  );
};
