import React from "react";
import Slider from "react-slick";
import { PrevArrow, NextArrow } from './ArrowComponents';
import { Link } from "react-router-dom";
import logo from '../logo-hindi.jpeg';
import banner1 from './banner1.jpg';
import banner2 from './banner2.jpg';
import banner3 from './banner3.jpg';
import './BannerSlider.css';

const BannerSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
    };

  return (
    <div>
    <div className="App-header">
          <img src={logo}  width="200" height="200" className="App-logo-bhagwati" alt="logo" />
          <h2>Bhagwati Engineer Works</h2>
        </div>
    <div className="banner-slider">
      <Slider {...settings}>
        <div>
          <Link to="/products">
            <img src={banner1} alt="Banner 1" />
          </Link>
        </div>
        <div>
          <Link to="/products">
            <img src={banner2} />
          </Link>
        </div>
        <div>
          <Link to="/products">
            <img src={banner3} alt="Banner 3" />
          </Link>
        </div>
        <div>
          <Link to="/products">
            <img src={banner1} alt="Banner 4" />
          </Link>
        </div>
        <div>
          <Link to="/products">
            <img src={banner2} alt="Banner 4" />
          </Link>
        </div>
        <div>
          <Link to="/products">
            <img src={banner3} alt="Banner 4" />
          </Link>
        </div>


        {/* Add more banner slides here */}
      </Slider>
    </div>
    </div>
  );
};

export default BannerSlider;
