import React, { useState } from 'react';
import './ContactUs.css'; // Import the CSS file for styling


export default function ContactUs() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form Submitted:', formData);
        alert('Thank you for contacting us!');
        setFormData({ name: '', email: '', message: '' }); // Reset form
    };

    return (
        <div className="contact-container">
            <div className="contact-details">
                <h2>Contact Details</h2>

                <div className="contact-person">
                    <strong>Contact Person : </strong>
                    <span>Amrutlal Chauhan (Proprietor)</span>
                </div>

                <div className="address">
                    <strong>Address:</strong>
                    <p>
                        Bhagwati Engg. Works
                        <br />
                        PLOT NO - 29, NR-GHAS GODOWN,
                        <br />
                        GONPATH ROAD,
                        <br />TALAJA, Talaja - 364140,
                        <br />Bhavnagar, Gujarat, India
                    </p>
                    <a href='https://maps.app.goo.gl/cUQdmouAW1Xqi5vc9' target='_blank'>Get Direction</a>
                </div>

                <div className="phone">
                    <strong>Call Us:</strong> 08046067676
                </div>
                <div className="other-contact">
                    <strong>Other Contact Details:</strong>
                    <ul>
                        <li>Branch Office: Amrutlal Chauhan, Gujarat, India</li>
                    </ul>
                </div>
            </div>
            <div className='contact-info'>
                <h1>Contact Us</h1>
                <p>We did love to hear from you! Please fill out the form below:</p>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Your name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />

                    <label htmlFor="email">Mobile Number</label>
                    <input
                        type="telephone"
                        id="mobile"
                        name="mobile"
                        placeholder="Your number"
                        value={formData.telephone}
                        onChange={handleChange}
                        required
                    />

                    <label htmlFor="message">What’s on your mind? </label>
                    <textarea
                        id="message"
                        name="message"
                        placeholder="What Are You Looking For"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    ></textarea>

                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
}
