import React, { useState } from 'react';
import './GetAQuote.css'; // Import CSS file for styling

export default function GetAQuote() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        product: '',
        quantity: '',
        message: '',
    });

    // Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Quote Request Submitted:', formData);
        alert('Thank you! Your quote request has been submitted.');
        setFormData({ name: '', email: '', product: '', quantity: '', message: '' }); // Reset form
    };

    return (
        <div className="quote-container">
            <h1>Get a Quote</h1>
            <p>Please fill out the form below to request a quote for your needs:</p>
            <form onSubmit={handleSubmit} className="quote-form">
                <label htmlFor="name">Full Name</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter your name"
                    required
                />

                <label htmlFor="email">Email Address</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter your email"
                    required
                />

                <label htmlFor="product">Product</label>
                <input
                    type="text"
                    id="product"
                    name="product"
                    value={formData.product}
                    onChange={handleChange}
                    placeholder="Enter the product you're interested in"
                    required
                />

                <label htmlFor="quantity">Quantity</label>
                <input
                    type="number"
                    id="quantity"
                    name="quantity"
                    value={formData.quantity}
                    onChange={handleChange}
                    placeholder="Enter the quantity"
                    required
                />

                <label htmlFor="message">Additional Message</label>
                <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Any additional information?"
                ></textarea>

                <button type="submit" className="quote-submit-btn">Request Quote</button>
            </form>
        </div>
    );
}
