import React from "react";

export default function TermsOfService() {
    return (
        <div className="terms-container">
            <h1>Terms of Service</h1>
            <p>
                Welcome to Bhagwati Engineer Works. By using our website and services, you agree to comply with and be bound by the following terms and conditions. Please review these terms carefully.
            </p>

            <h2>1. Acceptance of Terms</h2>
            <p>
                By accessing or using our website, you confirm that you have read, understood, and agree to these Terms of Service. If you do not agree to any part of these terms, please do not use our website.
            </p>

            <h2>2. Use of Our Website</h2>
            <p>
                You agree to use our website for lawful purposes only and not to:
                <ul>
                    <li>Engage in any activity that disrupts or interferes with the website's functionality.</li>
                    <li>Upload, share, or transmit harmful or malicious content.</li>
                    <li>Attempt to gain unauthorized access to our systems or data.</li>
                </ul>
            </p>

            <h2>3. Orders and Payments</h2>
            <p>
                When placing an order through our website:
                <ul>
                    <li>You agree to provide accurate and complete information.</li>
                    <li>Payment must be made in full at the time of purchase unless otherwise agreed upon.</li>
                    <li>We reserve the right to cancel or refuse orders at our discretion.</li>
                </ul>
            </p>

            <h2>4. Product Information</h2>
            <p>
                While we strive to provide accurate product descriptions and specifications, we do not guarantee that all information is error-free. Images may vary slightly from the actual product.
            </p>

            <h2>5. Intellectual Property</h2>
            <p>
                All content on this website, including text, images, logos, and designs, is the property of Bhagwati Engineer Works or its licensors. You may not copy, reproduce, or distribute any content without prior written permission.
            </p>

            <h2>6. Limitation of Liability</h2>
            <p>
                We are not responsible for any damages resulting from the use of our website, including but not limited to:
                <ul>
                    <li>Loss of data or profits.</li>
                    <li>Service interruptions.</li>
                    <li>Unauthorized access to your account or information.</li>
                </ul>
            </p>

            <h2>7. Changes to Terms</h2>
            <p>
                We reserve the right to update these Terms of Service at any time. It is your responsibility to review this page periodically for any changes.
            </p>

            <h2>8. Governing Law</h2>
            <p>
                These terms shall be governed by and construed in accordance with the laws of India. Any disputes arising from these terms will be subject to the exclusive jurisdiction of the courts in Gujarat, India.
            </p>

            <h2>9. Contact Us</h2>
            <p>
                If you have any questions about these Terms of Service, please contact us at:
                <br />
                <strong>Email:</strong> support@bhagwatiworks.com
                <br />
                <strong>Phone:</strong> +91-08046067676
            </p>
        </div>
    );
}
