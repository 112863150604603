import React from 'react';
import { Link } from "react-router-dom";
import './ProductDetail.css'; // Import the CSS for styling
import Image from './images/mava-khoya-machine.jpg'

const ProductDetail = () => {
    return (
        <div className="product-detail-container">
            <div className="product-image">
                <img 
                    src={Image} 
                    alt="Product" 
                    className="product-img" 
                />
            </div>
            <div className="product-info">
                <h1 className="product-title">Dough Mixer 20 KG</h1>
                <table className="specs-table">
                    <tbody>
                        <tr>
                            <td><strong>Description</strong></td>
                            <td><strong>20 KG</strong></td>
                        </tr>
                        <tr>
                            <td>Dimension (W x L x H)</td>
                            <td>40" x 24" x 42"</td>
                        </tr>
                        <tr>
                            <td>Body</td>
                            <td>MS</td>
                        </tr>
                        <tr>
                            <td>Bowl</td>
                            <td>SS 304</td>
                        </tr>
                        <tr>
                            <td>Capacity</td>
                            <td>20 KG</td>
                        </tr>
                        <tr>
                            <td>Weight (Approx)</td>
                            <td>125 Kg</td>
                        </tr>
                        <tr>
                            <td>Main Motor (Optional 3 Phase)</td>
                            <td>1.5 HP</td>
                        </tr>
                        <tr>
                            <td>Power Consumption / Hour</td>
                            <td>1.5 Unit</td>
                        </tr>
                    </tbody>
                </table>
                <button className='quote-button'><Link to="/get-quote">GET A QUOTE</Link></button>
            </div>
        </div>
    );
}

export default ProductDetail;
