import React from "react";

export default function PrivacyPolicy() {
    return (
        <div className="privacy-policy-container">
            <h1>Privacy Policy</h1>
            <p>
                At Bhagwati Engineer Works, your privacy is of utmost importance to us. This Privacy Policy outlines how we collect, use, and protect your personal information when you visit our website or purchase our products.
            </p>
            
            <h2>1. Information We Collect</h2>
            <p>
                We may collect the following types of information:
                <ul>
                    <li>Personal Information: Name, email address, phone number, and shipping address.</li>
                    <li>Payment Information: Credit/debit card details or payment processing information.</li>
                    <li>Website Usage Data: IP address, browser type, and pages visited.</li>
                </ul>
            </p>

            <h2>2. How We Use Your Information</h2>
            <p>
                The information we collect is used to:
                <ul>
                    <li>Process orders and payments.</li>
                    <li>Provide customer support and respond to inquiries.</li>
                    <li>Improve our website and services.</li>
                    <li>Send promotional offers, updates, and newsletters (with your consent).</li>
                </ul>
            </p>

            <h2>3. Data Protection</h2>
            <p>
                We implement strict security measures to protect your personal information from unauthorized access, disclosure, or alteration. However, no system is completely secure, and we cannot guarantee absolute security.
            </p>

            <h2>4. Sharing of Information</h2>
            <p>
                We do not sell or share your personal information with third parties, except:
                <ul>
                    <li>To trusted partners for order fulfillment and payment processing.</li>
                    <li>When required by law or to protect our legal rights.</li>
                </ul>
            </p>

            <h2>5. Your Rights</h2>
            <p>
                You have the right to:
                <ul>
                    <li>Access, update, or delete your personal information.</li>
                    <li>Opt out of promotional communications.</li>
                </ul>
                To exercise these rights, please contact us at <strong>support@bhagwatiworks.com</strong>.
            </p>

            <h2>6. Changes to This Policy</h2>
            <p>
                We reserve the right to update this Privacy Policy at any time. Please review this page periodically for any changes.
            </p>

            <h2>7. Contact Us</h2>
            <p>
                If you have any questions or concerns about this Privacy Policy, please contact us at:
                <br />
                <strong>Email:</strong> support@bhagwatiworks.com
                <br />
                <strong>Phone:</strong> +91-08046067676
            </p>
        </div>
    );
}
