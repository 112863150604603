import React, { useState } from 'react';
import './Menu.css'; // Import the CSS for styling
import { Link } from "react-router-dom";
import logo from '../bhagwati-eng-works-120x120.webp';

export default function Menu() {
    const [openMenu, setOpenMenu] = useState(null);

    const handleMouseEnter = (menuName) => {
        setOpenMenu(menuName);
    };

    const handleMouseLeave = () => {
        setOpenMenu(null);
    };

    return (
        <div className="menu-container">
            <ul className="menu">
                <li class="logo"><Link to="/"><img src={logo}  width="50" height="50" className="App-logo-bhagwati" alt="logo" /></Link></li>

                <li
                    className="menu-item"
                    onMouseEnter={() => handleMouseEnter("products")}
                    onMouseLeave={handleMouseLeave}
                >
                    <Link to="/products">Products</Link>
                    {openMenu === "products" && (
                        <ul className="submenu">
                            <li><Link to="/products">Mawa And Khoya Making Machine</Link></li>
                            <li><Link to="/products">Mawa Making Machine</Link></li>
                            <li><Link to="/products">Shrikhand Making Machine</Link></li>
                            <li><Link to="/products">Milk Rabdi Machine</Link></li>
                            <li><Link to="/products">Namkeen Making Machine</Link></li>
                            <li><Link to="/products">Khoya Making Machine</Link></li>
                            <li><Link to="/products">Atta Making Machine</Link></li>
                            <li><Link to="/products">Milk Boiling Machine</Link></li>
                            <li><Link to="/products">Kaju Musta Making Machine</Link></li>
                            <li><Link to="/products">Continuous Snacks Fryer</Link></li>
                            <li><Link to="/products">Mava Making Machines</Link></li>
                            <li><Link to="/products">Commercial Kitchen Equipments</Link></li>
                        </ul>
                    )}
                </li>
                <li><Link to="/contact">Contact Us</Link></li>
            </ul>
        </div>
    );
}
