import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BannerSlider from "./Banner/BannerSlider";
import Menu from './Menu/Menu';
import ContactUs from './InnerPages/ContactUs';
import ProductDetail from './Products/ProductDetail';
import Footer from './Footer';
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import GetAQuote from './InnerPages/GetAQuote';
import '@fortawesome/fontawesome-free/css/all.min.css';

function Homepage() {
  const location = useLocation();

  // Only show the header on the Home page
  console.log(location.pathname);
  if (location.pathname === "/") {
      return <BannerSlider />
  }

  return null; // Do not render the header on other pages
}

class App extends Component {
  render() {
    return (
      <Router>
      <div className="App">
        <Menu />
        <Homepage />
        
        <Routes>
            <Route path="/" />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/products" element={<ProductDetail />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/get-quote" element={<GetAQuote />} />
        </Routes>
        <Footer />
      </div>
      </Router>
    );
  }
}

export default App;
