// import React from 'react-dom';
// import ReactDom from 'react-dom';
// import App from './App';

// ReactDom.render(
//   <App />,
//   document.getElementById('root')
// );
  
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';

import App from './App';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);